<template>
  <div class="appInfo" style="height: 600px; width: 100%; margin: 0 auto;">
  <div class="ekb" style="height: 80px; width:400px; position: absolute; margin-left:200px ;  margin-right: auto;">
  <label style="font-weight: bold;font-size: 26px;">易快报</label>
   <el-form ref="ekbForm" :model="ekbForm" label-width="80px" style=" margin-top: 50px;">

    <el-form-item label="环境">
      <el-input v-model="ekbForm.appEvn"></el-input>
    </el-form-item>

    <el-form-item label="AppKey">
      <el-input v-model="ekbForm.appKey"></el-input>
    </el-form-item>
    <el-form-item label="AppSecret">
        <el-input v-model="ekbForm.appSecret"></el-input>
      </el-form-item>

    <el-form-item label="描述">
      <el-input type="textarea" v-model="ekbForm.desc"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">保存</el-button>
      <el-button>取消</el-button>
    </el-form-item>
  </el-form>
  </div>

   <div class="ht" style="height: 80px; width: 400px; position: relative; margin-left: auto;
  margin-right: 300px;" >  
    <label style="font-weight: bold; font-size: 26px;">惠通商旅</label>
     <el-form ref="htForm" :model="htForm" style="margin-top: 50px;" label-width="80px">

       <el-form-item label="环境">
          <el-input v-model="htForm.appEvn"></el-input>
        </el-form-item>

      <el-form-item label="公钥">
        <el-input v-model="htForm.publicKey"></el-input>
      </el-form-item>

        <el-form-item label="私钥">
          <el-input v-model="htForm.privateKey"></el-input>
        </el-form-item>

         <el-form-item label="secretKey">
            <el-input v-model="htForm.secretKey"></el-input>
          </el-form-item>

             <el-form-item label="authId">
              <el-input v-model="htForm.authId"></el-input>
            </el-form-item>
     
      <el-form-item label="描述">
        <el-input type="textarea" v-model="htForm.desc"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button>取消</el-button>
      </el-form-item>
    </el-form>
    </div>

  </div>
</template>

<script>
import html2canvas from 'html2canvas';
export default {
  name: "AppInfo",
  components: {
   
  },
  data() {
    return {
     ekbForm: {
        appEvn: '',
        appKey: '',
        appSecret: '',
        desc: ''
      },
     htForm: {
        appEvn: '',
        publicKey: '',
        privateKey: '',
        secretKey: '',
        authId: '',
        desc: ''
      }
    };
  },
  mounted() {
    this.queryLayout();
    this.getMenulist();
  },
  beforeDestroy() {
    this.$off("saveLayout");
  },
  methods: {
    // 获取可拖动的元素
    getMenulist() {
      console.log("getMenulist...");
    },
    saveLayout(data) {
      console.log("saveLayout...");
      console.log(data);
    },
    queryLayout() {
      console.log("queryLayout...");
    },
  },
};
</script>


<style scoped lang="less">
.appInfo {
  height: 100%;
}

.ekb {
    
  }

  .ht {
    
  }

</style>
